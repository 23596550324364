<template>
        <section class="admin-container">   
            <v-layout height="60vh">
                 <v-row>
                    <v-col cols="12"  lg="2" md="2" sm="12" xs="12">
                        <SideBar />
                    </v-col>
                    <v-col cols="12"  lg="9" md="9" sm="12" xs="12">
                        <Users v-if="routeName === 'users' || routeName === 'admin' "/>
                        <EditUser v-if="routeName === 'single-user'"/>
                        <Contacts v-if="routeName === 'contacts'"/>
                        <Team v-if="routeName === 'team'"/>
                        <EditTeam v-if="routeName === 'singleTeam'"/>
                        <Blog v-if="routeName === 'blog-admin'"/>
                        <NewBlog v-if="routeName === 'new-blog'"/>
                        <BlogCategories v-if="routeName === 'blog-categories'"/>
                        <newCategory v-if="routeName === 'new-category'"/>
                        <EditBlogCategory v-if="routeName === 'single-blog-categories'"/>

                        
                        <EditBlog v-if="routeName === 'singleBlog'"/>
                        <FAQs v-if="routeName === 'admin-FAQs'"/>
                        <NewFAQ v-if="routeName === 'new-FAQ'"/>
                        <EditFAQ v-if="routeName === 'singleFAQ'"/>
                        <FaQCategories v-if="routeName === 'FAQ-categories'"/>
                        <NewFaQCategories v-if="routeName === 'new-faq-category'"/>
                        <EditFAQCategory v-if="routeName === 'singleFAQCategory'"/>

                        <Countries v-if="routeName === 'countries'"/>
                        <NewCountry v-if="routeName === 'new-country'"/>
                        <EditCountry v-if="routeName === 'country'"/>

                        <PaymentVolumes v-if="routeName === 'volumes'"/>
                        <EditPaymentVolume v-if="routeName === 'volume'"/>

                        <SalesContacts v-if="routeName === 'salesContacts'"/>
                        <EditSalesContact v-if="routeName === 'salesContact'"/>
                        


                       
                    </v-col>
                 </v-row>

            </v-layout>
        </section>
</template>

<script>
import { mapGetters } from 'vuex';

import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Admin',
    components: {
        SideBar: () => import('./sideBar'),
        Users: () => import('./users'),
        Contacts: () => import('./contacts'),
        Team: () => import('./team'),
        EditTeam: () => import('./team/editTeam'),
        Blog: () => import('./blog'),
        NewBlog: () => import('./blog/newBlog'),
        BlogCategories: () => import('./blog/categories'),
        newCategory: () => import('./blog/newCategory'),
        EditBlog: () => import('./blog/editBlog'),
        FAQs: () => import('./FAQS'),
        NewFAQ: () => import('./FAQS/newFAQ'),
        EditFAQ: () => import('./FAQS/editFAQ'),
        EditUser: () => import('./users/editUser'),
        FaQCategories: () => import('./FAQS/categories'),
        NewFaQCategories: () => import('./FAQS/newCategories'),
        EditFAQCategory: () => import('./FAQS/editFAQCategory'),
        Countries: () => import('./country'),
        NewCountry: () => import('./country/newCountry'),
        EditCountry: () => import('./country/editCountry'),
        PaymentVolumes: () => import('./paymentVolumes'),
        EditPaymentVolume: () => import('./paymentVolumes/editVolumes'),
        SalesContacts: () => import('./salesContacts'),
        EditSalesContact: () => import('./salesContacts/editSalesContact'),
        EditBlogCategory: () => import('./blog/editCategory'),

        


        


    },
    mixins: [colorMixin, coreMixin],

    data () {
        return {
            user: null,
        };
    },
     computed: {
    ...mapGetters(['isAuthenticated', 'getSessionData']),
    },
    watch :{
        getSessionData(data) {
            this.user = data;
        },
    },



}
</script>
